import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { OpenlayersHelper } from '@helpers/openlayers.helper';
import { UserData, UserDataObject } from '@models/user-data';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UserDataService {
  private dimDrawings = new BehaviorSubject<boolean>(false);
  private drawingError = new BehaviorSubject<string>(null);
  private drawings = new BehaviorSubject<UserDataObject[]>(null);
  private http = inject(HttpClient);
  private savedDrawing = new BehaviorSubject<UserDataObject>(null);

  get getDimDrawings$(): Observable<boolean> {
    return this.dimDrawings.asObservable();
  }
  get getDrawingError$(): Observable<string> {
    return this.drawingError.asObservable();
  }
  get getDrawings$(): Observable<UserDataObject[]> {
    return this.drawings.asObservable();
  }
  get getNewlySavedDrawing$(): Observable<UserDataObject> {
    return this.savedDrawing.asObservable();
  }

  deleteDrawing(id: string) {
    const url = `user/data/drawing/${id}`;
    return this.http.delete(`${ApiConfig.proxiedBackendUrl}${url}`, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Fetch a drawing based on id from backend
   * @param {number} id      dim value
   */
  getDrawing(id: number) {
    return this.http.get<UserDataObject>(ApiConfig.proxiedBackendUrl + 'user/data/' + id);
  }

  /**
   * Post request to backend to store the drawn features on the map for the session
   * @param {string}  name  file name
   * @param {object} canvas  open layers featurecollection in KML format
   */
  postCanvasForUser(name: string, canvas: any | string) {
    const body = {
      data: OpenlayersHelper.prefixRelativeImageUrls(canvas),
      name: name,
    };

    const url = 'user/data/drawing/';

    return this.http.post<UserDataObject>(`${ApiConfig.proxiedBackendUrl}${url}`, body, {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
    });
  }

  /**
   * Put a recently created drawing into an observable so we can populate saved elements with it
   * No need to call refresh, we already have the data and info about the new drawing.
   * @param {UserDataObject} drawing      the newly saved drawing
   */
  putRecentlySavedDrawing(drawing: UserDataObject) {
    this.savedDrawing.next(drawing);
  }

  /**
   * Call on backend to return the data drawings associated with the user
   */
  refreshDrawings() {
    this.savedDrawing.next(null);
    this.setDrawings(null);
    this.http.get<UserData>(ApiConfig.proxiedBackendUrl + 'user/data').subscribe(
      success => {
        if (success.DRAWING) {
          this.drawingError.next(null);
          this.setDrawings(success.DRAWING);
        }
      },
      () => this.drawingError.next('Kunne ikke hente lagrede elementer.'),
    );
  }

  /**
   * Set whether or not the drawings should lower opacity
   * @param {boolean} value      dim value
   */
  setDimDrawings(value: boolean) {
    this.dimDrawings.next(value);
  }

  /**
   * Set array with the user's drawings
   * @param {UserDataObject}  d   the drawings
   */
  setDrawings(d: UserDataObject[]) {
    this.drawings.next(d);
  }
}
