import { OpenlayersHelper } from '@helpers/openlayers.helper';
import LayerGroup from 'ol/layer/Group';
import Group from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import Image from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';

export class LayerTree {
  layerTree: any[];

  constructor(layerconfig: any, groupconfig: any, layerTreeSetup: any, eService: any) {
    this.layerTree = [];
    for (const l of layerTreeSetup) {
      if (l.parentId === null /* && l.isGroup */ && groupconfig[l.layerId]) {
        /* This is root node, making LayerGroup for this*/
        const entry = new LayerTreeGroup(groupconfig[l.layerId]);
        // Always make the root node the first entry of the layerTree
        this.layerTree.unshift(entry);
      } else if (!groupconfig[l.layerId]) {
        // This is a layer
        const entry = new ThemeLayer(l.parentId, '', layerconfig[l.layerId], eService);

        // add layer to its parantsLayerGroup
        this.addToParentGroup(l.parentId, entry.layer);
        this.layerTree.push(entry);
      } else {
        // This is a group
        const entry = new LayerTreeGroup(groupconfig[l.layerId]);
        this.addToParentGroup(l.parentId, entry.layerGroup);
        this.layerTree.push(entry);
      }
    }
  }

  /*
    addToParentGroup
   */
  private addToParentGroup(parent: string, childLayer: ImageLayer<any> | LayerGroup) {
    let p: any;
    let found = false;
    for (const l of this.layerTree) {
      if (parent === l.id) {
        p = l;
        found = true;
        break;
      }
    }
    if (!found) {
      return;
    }
    const groupLayers = p.layerGroup.getLayers();
    groupLayers.extend([childLayer]);
    return;
  }

  getLayer(id: string) {
    for (const entry of this.layerTree) {
      if (entry.id === id) {
        return entry;
      }
    }
    return null;
  }
}

export class LayerTreeEntry {
  isGroup: boolean;
  constructor(type: 'group' | 'layer') {
    this.isGroup = type === 'group';
  }
}

export class LayerTreeGroup extends LayerTreeEntry {
  checked: boolean;
  expand: boolean;
  icon: string;
  id: string;
  infolink: string;
  layerGroup: Group;
  qtip: string;
  title: string;
  constructor(config: any) {
    // indicate that this is a layerTreeGroup
    super('group');
    this.title = config.title;
    this.id = config.id;
    this.checked = false;
    this.icon = config.logo;
    this.qtip = config.qtip;
    this.infolink = config.infolink;
    this.layerGroup = new Group({});
    this.expand = config.expand;
  }
}

export class ThemeLayer extends LayerTreeEntry {
  checked: boolean;
  displayname: string;
  id: string;
  layer: Image<any>;
  name: string;

  parent: LayerTreeGroup;

  ui_options: any;

  constructor(
    public parentGroup: string,
    public indentClass: string,
    private config: any,
    private eService: any,
  ) {
    // indicate that this is a themeLayer
    super('layer');
    // super(config.ui_options.name, config.id, ThemeType.Layer, indentClass);
    if (typeof config !== 'undefined') {
      // sjekk for minScale/maxScale og gjør dette om via functionen getResolution(scale)
      this.id = config.id;
      this.checked = false;
      this.name = config.ui_options.name;
      if (config !== null) {
        this.createLayer(config);
        this.ui_options = config.ui_options;
      }
    } else {
      this.id = 'Unknown_id';
      this.name = 'Unknown name';
      this.displayname = 'Unknown name';
      this.layer = null;
    }
  }

  private createLayer(config: any) {
    if (config.options && config.id) {
      // copy the options part of the config:
      const olConfig = Object.assign({}, config.options);
      olConfig.id = config.id;
      // Prepare the source-object:

      const n = config.ui_options?.name;
      if (config.source && config.ui_options && config.ui_options.sourceType) {
        switch (config.ui_options.sourceType) {
          case 'ImageWMS':
            if (config.source.url.includes('STYLES=')) {
              // Unntak freda kulturminner
              config.source.params.STYLES = null;
            }
            olConfig.source = new ImageWMS(config.source);
            olConfig.source.on('imageloaderror', () =>
              this.eService.putError(`Henting av temakartet ${n} feilet. Prøv igjen senere!`),
            );
            break;
          default:
            this.layer = null;
            return;
        }
      } else {
        this.layer = null;
        return;
      }

      this.layer = new Image(olConfig);
      this.layer.set('name', config.ui_options.name);
      this.layer.set('displayname', config.ui_options.name);
      if (Object.prototype.hasOwnProperty.call(config.ui_options, 'featureinfo')) {
        this.layer.set('featureinfo', config.ui_options.featureinfo);
      }
      if (config.ui_options.sourceType) {
        this.layer.set('sourceType', config.ui_options.sourceType);
      }
      if (config.ui_options.legend) {
        this.layer.set('legend', config.ui_options.legend);
      }

      const minScale = config.ui_options.minScale;
      if (minScale !== undefined) {
        // this.layer.setMaxResolution(200);
        this.layer.setMaxResolution(OpenlayersHelper.getResolution(minScale));
      }
      const maxScale = config.ui_options.maxScale;
      if (maxScale !== undefined) {
        this.layer.setMinResolution(20);
      }
    }
  }
}
