import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { LayerTree } from '@models/layer-tree';
import { ConfigService } from '@services/config.service';
import { ErrorService } from '@services/error.service';
import { map, share } from 'rxjs/operators';

@Injectable()
export class LayerTreeService {
  private _cs = inject(ConfigService);
  private config: any;

  private eService = inject(ErrorService);
  private url = `${ApiConfig.proxiedBackendUrl}configuration/otherlayers`;
  asyncThemeLayers: LayerTree;

  getAsyncThemeLayers() {
    return this._cs.getConfig(this.url).pipe(
      map(x => {
        if (!this.asyncThemeLayers) {
          this.config = x;
          this.asyncThemeLayers = new LayerTree(
            this.config.layers,
            this.config.layerGroups,
            this.config.otherLayerOrder,
            this.eService,
          );
        }
        return this.asyncThemeLayers;
      }),
      share(),
    );
  }
}
