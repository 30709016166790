import { GkAction } from '@store/gk/gk-action';

export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';

const DEFAULT = false;

export function loadingReducer(state: boolean = DEFAULT, action: GkAction) {
  switch (action.type) {
    case UPDATE_LOADING_STATE:
      return action.payload;

    default:
      return state;
  }
}
