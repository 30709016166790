import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { combineLatest, interval } from 'rxjs';
import { concatMap, map, startWith } from 'rxjs/operators';

@Injectable()
export class TicketService {
  private _http = inject(HttpClient);

  // private tickets: any = {};
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private nib_project_ticket: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private nib_ticket: string;

  addTicketNIBlayers() {
    if (this.nib_ticket) {
      return [this.nib_ticket, this.nib_project_ticket];
    }
    return undefined;
  }

  addTicketTolayers(layerTree, tickets) {
    // console.log('tickets: ' + tickets[0].fkb2_ticket)
    for (const entry of layerTree) {
      if (Object.prototype.hasOwnProperty.call(entry, 'layer')) {
        if (Object.prototype.hasOwnProperty.call(entry.ui_options, 'ticket_type')) {
          const ticket_type = entry.ui_options.ticket_type;
          if (ticket_type === 'fkb2') {
            entry.layer.getSource().getParams().TICKETID = tickets[1].fkb2_ticket;
          } else if (ticket_type === 'topo3') {
            entry.layer.getSource().getParams().TICKETID = tickets[2].topo3_ticket;
          }
        }
      }
    }
  }

  getTickets() {
    const ticketInt = 3000000;
    // get new ticket every 50min, 50*60*1000
    const defaultOptions = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',

        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'max-age': '0',

        Pragma: 'no-cache',
      },
    };
    const token = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        this._http.get(ApiConfig.token_nib, defaultOptions).pipe(
          map(res => {
            return res['token'];
          }),
        ),
      ),
    );
    const ticket_topo3 = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() => this._http.get(ApiConfig.ticket_topo3, defaultOptions)),
    );
    const ticket_nib = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        this._http.get(ApiConfig.ticket_nib, defaultOptions).pipe(
          map(res => {
            this.nib_ticket = res['nib_ticket'];
            return res;
          }),
        ),
      ),
    );
    const ticket_nib_project = interval(ticketInt).pipe(
      startWith(0),
      concatMap(() =>
        // this._http.get('//gk01utv.int.nibio.no/token/ticket_topo3.jsp').map(res => res.json()));
        this._http.get(ApiConfig.ticket_nib_project, defaultOptions).pipe(
          map(res => {
            this.nib_project_ticket = res['nib_project_ticket'];
            return res;
          }),
        ),
      ),
    );
    return combineLatest([token, ticket_topo3, ticket_nib, ticket_nib_project]);
  }
}
