import { inject, Injectable } from '@angular/core';
import { isOlColor } from '@app-types/ol-color.typeguard';
import { ApiConfig } from '@config/api.config';
import { MapConfig } from '@config/map.config';
import { DrawHelper } from '@helpers/draw.helper';
import { OpenlayersHelper } from '@helpers/openlayers.helper';
import { StringHelper } from '@helpers/string.helper';
import { AreaStatisticsService } from '@services/area-statistics.service';
import { BackgroundMapsService } from '@services/background-maps.service';
import { MapService } from '@services/map.service';
import { PrintService } from '@services/print.service';
import { driftsenterStyle } from '@tools/mapStyles';
import { PrintTools } from '@tools/print.tools';
import { nanoid } from 'nanoid';
import { Feature, Map as OLMap, View } from 'ol';
import { Color } from 'ol/color';
import { Extent } from 'ol/extent';
import GeoJSON, { GeoJSONFeature } from 'ol/format/GeoJSON';
import { Geometry } from 'ol/geom';
import BaseLayer from 'ol/layer/Base';
import Group from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import Layer from 'ol/layer/Layer';
import VectorLayer from 'ol/layer/Vector';
import { Projection } from 'ol/proj';
import ImageWMS from 'ol/source/ImageWMS';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import sourceVector from 'ol/source/Vector';
import WMTS from 'ol/source/WMTS';
import Circle from 'ol/style/Circle';
import { FlatStyleLike } from 'ol/style/flat';
import StyleIcon from 'ol/style/Icon';
import ImageStyle from 'ol/style/Image';
import Style, { StyleLike } from 'ol/style/Style';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { ViewOptions } from 'ol/View';
import { PropertyService } from 'src/app/core/services/property.service';

@Injectable()
export class PrintMapService {
  readonly #areaStatisticsService = inject(AreaStatisticsService);
  readonly #backgroundMapsService = inject(BackgroundMapsService);
  readonly #mapService = inject(MapService);
  readonly #printService = inject(PrintService);
  readonly #propertyService = inject(PropertyService);

  private encoders = {
    Group: g => {
      return { name: g.id, opacity: g.opacity != null ? g.opacity : 1.0 };
    },

    Layer: layer => {
      const p = layer.getProperties();
      const opacityVal = layer.getOpacity() ?? p.opacity;
      return { name: p.id, opacity: opacityVal ?? 1.0 };
    },

    Vector: (layer: VectorLayer<VectorSource>, resolution: number, proj: Projection) => {
      const layerName = layer.getProperties()['id'];
      const features = layer.getSource().getFeatures();
      const enc = this.encoders.Layer.call(this, layer);
      const format = new GeoJSON();
      const encFeatures = [];
      const encStyles = { version: '2' };

      // http://mapfish.github.io/mapfish-print-doc/styles.html
      features.forEach(feature => {
        if (feature.get('show') === false) {
          return;
        }

        // If there is no overlap with the printbox, exclude this from print (Mapfish payload)
        if (!feature.getGeometry().intersectsExtent(this.#printService.getBBox())) {
          return;
        }

        // remove it to avoid mapfish error (Sometimes added as undefined, so remove it always)
        feature.unset('old_style', false);
        feature.unset(DrawHelper.CACHED_STYLE_KEY, false);

        // https://github.com/ai/nanoid/
        const style_id = nanoid();
        const styleNameId = "[style_id = '" + style_id + "']";
        // Set style_id on feature
        feature.set('style_id', style_id);
        // feature to Geojson
        const encJSON: GeoJSONFeature = format.writeFeatureObject(feature);
        const z = PrintTools.getZindex(layer, feature, resolution);
        encJSON['zIndex'] = z;
        encJSON.properties['zIndex'] = z;
        if (z < 0) {
          encFeatures.push(encJSON);
        } else {
          const idx = PrintTools.getPosition(encFeatures, z);
          encFeatures.splice(idx, 0, encJSON);
        }
        // Get style from feature
        const sym = this.getTypeStyleArray(layer, feature, resolution);
        const style = { symbolizers: sym };

        if (feature.get('hasLabel') && feature.get('hasLabel') !== 'false') {
          const symText = PrintTools.makeLabel(feature);
          sym.push(symText);
        }
        // Mapfish demands either a style_id reference or a wildcard on style.
        // Connect style_id and style
        Object.assign(encStyles, { [styleNameId]: style });
      }); // end loop feature

      if (encFeatures.length === 0) {
        return null;
      } // "slett alle objekter" btn has been clicked
      const useWMS = true;
      if (useWMS && layerName.indexOf('GARDSKART') > -1) {
        // skip sending vector to MapFish
        return this.encodeGkAsWMS(layer, proj);
      }

      // console.log(`mapfish getting vector features`, encFeatures?.length, 'of total', features.length);

      Object.assign(enc, {
        geoJson: {
          features: encFeatures.reverse(),
          type: 'FeatureCollection',
        },
        style: encStyles,
        type: 'geoJson',
      });
      return enc;
    }, // end Vector

    WMS: function (layer: ImageLayer<ImageWMS>, proj: Projection) {
      const enc: any = {};
      const source = layer.getSource();
      const params = source.getParams();
      let layers: any[];
      if (!(params.LAYERS instanceof Array)) {
        layers = params.LAYERS.split(',') || [];
      } else {
        layers = params.LAYERS;
      }
      const crs = proj.getCode();
      let wmsUrl: string;
      const ticket = params.TICKETID || '';
      wmsUrl = source.getUrl();

      if (
        wmsUrl.indexOf('http:') !== 0 &&
        wmsUrl.indexOf('https:') !== 0 &&
        wmsUrl.indexOf('HTTP:') !== 0 &&
        wmsUrl.indexOf('HTTPS:') !== 0
      ) {
        wmsUrl = 'https:' + wmsUrl;
      }

      const styles = [''];
      let filter = '';

      Object.assign(enc, {
        baseURL: wmsUrl,
        customParams: {
          CRS: crs,
          TICKETID: ticket,
          TRANSPARENT: 'true',
        },
        imageFormat: 'png',
        layers: layers,
        opacity: layer.getOpacity() ?? params.opacity,
        styles: styles,
        type: 'WMS',
      });

      if (params.CQL_FILTER) {
        filter = params.CQL_FILTER;
        enc.customParams.CQL_FILTER = filter;
      }

      return enc;
    },

    WMTS: function (layer, proj: Projection) {
      const enc = {};
      const source = layer.getSource() || [];
      const matrixIds = (source.getTileGrid() as WMTSTileGrid).getMatrixIds();
      const matrices = PrintTools.makeMatriceForMapfish(matrixIds, source, proj);
      const baseUrl = source.urls[0].split('?')[0] || '';
      const token = source.urls[0].split('?')[1]?.split('=')[1] ? source.urls[0].split('?')[1].split('=')[1] : '';

      Object.assign(enc, {
        baseURL: baseUrl,
        customParams: token ? { gkt: token } : '',
        imageFormat: source.getFormat(),
        layer: source.getLayer(),
        matrices: matrices,
        matrixSet: source.getMatrixSet(),
        opacity: layer.getOpacity() ?? layer.opacity,
        requestEncoding: 'KVP',
        style: 'default',
        type: 'WMTS',
        version: '1.0.0',
      });
      return enc;
    },
  }; // end encoders

  /**
   * If the printbox's bounding box extent is supplied, use it for the layer.isVisible(view) method.
   */
  #getPrintboxView(): undefined | View {
    const bbox: Extent = this.#printService.getBBox();
    if (bbox) {
      const view = this.#mapService.getMap().getView();
      return new View({
        center: view.getCenter(),
        extent: bbox,
        maxResolution: view.getMaxResolution(),
        minResolution: view.getMinResolution(),
        rotation: view.getRotation(),
        smoothExtentConstraint: false,
        smoothResolutionConstraint: false,
        zoom: view.getZoom(),
      } as ViewOptions);
    }
    return undefined;
  }

  /**
   * The Printbox layer itself + any layer not visible within the Printbox should be excluded from print/Mapfish payload
   */
  #isValidMapfishLayer(olLayer: BaseLayer, printboxView?: View): boolean {
    if (!(olLayer instanceof Layer)) {
      // console.error(`olLayer instanceof Layer check failed`, olLayer.constructor.name);
      return false;
    }

    const properties = olLayer.getProperties();
    if (properties['id'] === 'printBox' || properties['id'] === 'ingen') {
      return false;
    }
    if (printboxView) {
      return olLayer.isVisible(printboxView);
    } else {
      return olLayer.isVisible();
    }
  }

  /**
   * ask for a WMS instead of vector
   * @param  layer vector layer|
   * @param  proj  current projection
   * @return       encoded layer for mapFish
   */
  private encodeGkAsWMS(layer, proj: Projection) {
    const layerName = layer.getProperties().id;
    const crs = proj.getCode();

    let wmsLayerName = 'gardskart_';
    const gkType = PrintTools.getGkTypeCode(this.#areaStatisticsService.gkType);
    const ortofoto = this.#backgroundMapsService.getSelected() === MapConfig.ORTHO;
    if (ortofoto) {
      wmsLayerName += 'ortofoto_';
    }
    wmsLayerName += gkType;

    const id = this.layer2farm(layerName);
    const parcelIds = this.#areaStatisticsService.getVisibleParcelList(id, layer);

    return {
      baseURL: ApiConfig.gkWms,
      customParams: {
        CRS: crs,
        DPI: '96',
        FORMAT_OPTIONS: 'dpi:96',
        MAP_RESOLUTION: '96',
        parcelids: parcelIds.join(','),
        TRANSPARENT: 'true',
      },
      imageFormat: 'image/png',
      layers: [wmsLayerName],
      opacity: layer.opacity || layer.getOpacity(),
      type: 'WMS',
    };
  }

  private encodeGroup(map: OLMap, group) {
    const mainThis = this;
    const encs = [];
    const zs = [];
    const subLayers = group.layers.getArray();

    subLayers.forEach(subLayer => {
      if (!this.#isValidMapfishLayer(subLayer, this.#getPrintboxView())) {
        return;
      }
      const enc = mainThis.encoders.Group.call(this, group);
      const layerEnc = mainThis.encodeLayer(map, subLayer);

      if (layerEnc) {
        Object.assign(enc, layerEnc);
        encs.push(enc);
        zs.push(subLayer.getZIndex());
      }
    });
    return { encs, zs };
  }

  private encodeLayer(myMap: OLMap, layer): any {
    const myProj = myMap.getView().getProjection();
    let encLayer;
    const resolution = myMap.getView().getResolution();
    const src = layer.getSource() || [];

    if (!(layer instanceof Group)) {
      let minResolution = 0;
      if (layer.minResolution >= 0) {
        minResolution = layer.minResolution;
      } else if (layer.getMinResolution() && layer.getMinResolution() >= 0) {
        minResolution = layer.getMinResolution();
      }
      let maxResolution = Infinity;
      if (layer.maxResolution >= 0) {
        maxResolution = layer.maxResolution;
      } else if (layer.getMaxResolution() && layer.getMaxResolution() >= 0) {
        maxResolution = layer.getMaxResolution();
      }
      if (resolution <= maxResolution && resolution >= minResolution) {
        if (src instanceof ImageWMS || src instanceof TileWMS) {
          encLayer = this.encoders.WMS.call(this, layer, myProj);
        }
        if (src instanceof sourceVector) {
          encLayer = this.encoders.Vector.call(this, layer, resolution, myProj);
        }
        if (src instanceof WMTS) {
          // WMS brukes for å supplere treig WMTS, kan fjernes etter hvert:
          const sub = this.#backgroundMapsService.getSubstituteWMS(layer.get('id'));
          if (sub) encLayer = this.encoders.WMS.call(this, sub, myProj);
          else encLayer = this.encoders.WMTS.call(this, layer, myProj);
        }
      }
    }
    return encLayer;
  }

  // http://mapfish.github.io/mapfish-print-doc/styles.html#mapfishJsonParser
  private getTypeStyleArray(layer: VectorLayer<VectorSource>, feature: Feature<Geometry>, resolution?: number): any[] {
    const defaultPointColor = [255, 0, 0, 1] as Color;
    const defaultTextColor = [0, 0, 0, 1] as Color;
    const defaultStrokeColor = [255, 255, 255, 1] as Color;
    const defaultKmlColor = [255, 255, 255, 1] as Color;
    const defaultKmlStrokeColor = [0, 0, 0, 1] as Color;
    const defaultWidth = 2;
    const isKMLFile = layer.getProperties()['fileFormat'] === 'kml';
    const layerName = layer.getProperties()['id'];
    const drawings = layerName === 'drawVectorLayer' || layer.getProperties()['savedDrawing'];
    const geoType = feature.getGeometry().getType().toLowerCase();
    const isPoint = geoType === 'point';
    const isLinestring = geoType === 'linestring';
    const isPolygon = geoType === 'polygon';
    const tsa = [];

    let styles: FlatStyleLike | StyleLike = drawings || isKMLFile ? feature.getStyle() : layer.getStyle();

    if (typeof styles === 'function') {
      styles = styles(feature, resolution) as Style;
    }
    if (!styles) {
      return tsa;
    }
    if (styles.constructor === Array) {
      styles = styles[0] as Style;
    } else {
      styles = styles as Style;
    }

    const fill = styles.getFill();
    const stroke = styles.getStroke();
    const img: ImageStyle | StyleIcon = styles.getImage();
    const isExternalIcon = img instanceof StyleIcon;
    const t = styles.getText();

    // console.log(`Ident:`, geoType, feature.get('name'), layer.get('id'));

    // Style for Driftssenter
    if (feature.get('name') === 'DRIFTSSENTER' || layer.get('id') === 'DRIFTSSENTER') {
      const dsImg = driftsenterStyle.getImage() as Circle;
      const dsPoint = {
        fillColor: dsImg.getFill().getColor(),
        graphicOpacity: 1,
        pointRadius: dsImg.getRadius(),
        strokeColor: dsImg.getStroke().getColor(),
        strokeOpacity: 1,
        strokeWidth: dsImg.getStroke().getWidth(),
        type: 'point',
      };
      tsa.push(dsPoint);
      return tsa;
    }

    // Style for Point
    if (isPoint && img) {
      const baseColor = PrintTools.namedColorToRgbString(
        drawings ? fill?.getColor().toString() || defaultPointColor.toString() : 'red',
      );
      const pFill = drawings || isExternalIcon ? fill : (img as Circle).getFill();
      const pStroke = drawings || isExternalIcon ? stroke : (img as Circle).getStroke();
      let pRadius: number = 16 * img.getScaleArray()[0]; // GK default, used in imported files, overridden below
      if (drawings) {
        pRadius = 24 * img.getScaleArray()[0];
      } else if (!isExternalIcon) {
        pRadius = (img as Circle).getRadius();
      }
      const [rgb, a] =
        drawings || isExternalIcon
          ? [baseColor, '1']
          : PrintTools.splitRgba(
              PrintTools.namedColorToRgbString(pFill?.getColor().toString() || defaultTextColor.toString()),
            );
      const [rgbS, aS] =
        drawings || isExternalIcon
          ? [baseColor, '1']
          : PrintTools.splitRgba(
              PrintTools.namedColorToRgbString(pStroke?.getColor().toString() || defaultTextColor.toString()),
            );
      const width = drawings || isExternalIcon ? defaultWidth : pStroke?.getWidth() || defaultWidth;
      const point = {
        fillColor: rgb,
        graphicOpacity: a,
        pointRadius: pRadius,
        strokeColor: rgbS,
        strokeOpacity: aS,
        strokeWidth: width,
        type: 'point',
      };

      // Make sure we have the correct full url to the image resource
      if (drawings || isExternalIcon) {
        const exceptions: string[] = ['http:', 'https:', '://'];
        const imgSrc = (img as StyleIcon).getSrc();
        const isAbsoluteUrl = exceptions.some(exception => imgSrc.startsWith(exception));

        // Since Mapfish accesses this from a different server, relative paths to images will not work.
        // Prefix URL in front of relative paths:
        point['externalGraphic'] = isAbsoluteUrl
          ? imgSrc
          : `${ApiConfig.imageUrl}${StringHelper.withLeadingSlash(imgSrc)}`;
      }

      // console.log(`point`, point);
      tsa.push(point);
    }

    // Style for line and stroke
    if (stroke && !drawings) {
      const [rgbS, aS] = PrintTools.splitRgba(
        PrintTools.namedColorToRgbString(stroke.getColor()?.toString() || defaultTextColor.toString()),
      );
      const line = {
        strokeColor: rgbS,
        strokeOpacity: aS,
        strokeWidth: stroke.getWidth() || defaultWidth,
        type: 'line',
      };
      // console.log(`line`, line, rgbS, aS);
      tsa.push(line);

      // Dashed line on top of border for added properties
      if (feature.get('added')) {
        const dashedLine = {
          strokeColor: 'rgb(255, 255, 255)',
          strokeDashstyle: 'dash',
          strokeOpacity: '0.9',
          strokeWidth: stroke.getWidth() || defaultWidth,
          type: 'line',
        };
        // console.log(`stroke`, dashedLine);
        tsa.push(dashedLine);
      }
    }

    // Style for lines drawn by user
    if (drawings && isLinestring) {
      const colorStr = PrintTools.namedColorToRgbString(stroke?.getColor()?.toString() || defaultTextColor.toString());
      const line = {
        strokeColor: PrintTools.splitRgba(PrintTools.namedColorToRgbString(colorStr))[0],
        strokeOpacity: 0.9,
        strokeWidth: stroke.getWidth() || defaultWidth,
        type: 'line',
      };
      // console.log(`LineString`, line, colorStr);
      tsa.push(line);
    }

    // KML files with line string seems to add in a polygon during print. Avoiding this through the check.
    if (fill && !drawings && !isLinestring) {
      const [rgb, a] = PrintTools.splitRgba(
        PrintTools.namedColorToRgbString(fill.getColor().toString() || defaultTextColor.toString()),
      );
      const layerOpacity = layer.getOpacity(); // [a] is feature opacity
      const [rgbS, aS] = PrintTools.splitRgba(
        PrintTools.namedColorToRgbString(stroke?.getColor().toString() || defaultTextColor.toString()),
      );
      const polygon = {
        fillColor: rgb,
        fillOpacity: a ?? layerOpacity,
        strokeColor: rgbS,
        strokeOpacity: aS,
        strokeWidth: stroke.getWidth() || defaultWidth,
        type: 'polygon',
      };
      // console.log(`KML polygon`, polygon, rgb, a, layerOpacity, rgbS, aS);
      tsa.push(polygon);
    }

    // Style for user drawn polygons
    if (drawings && isPolygon) {
      const colorStr = fill?.getColor()?.toString() || defaultTextColor?.toString();
      const [rgbS] = PrintTools.splitRgba(PrintTools.namedColorToRgbString(colorStr));
      const [rgb, a] = fill
        ? PrintTools.splitRgba(PrintTools.namedColorToRgbString(fill.getColor().toString()))
        : [rgbS, 0];

      const polygon = {
        fillColor: rgb,
        fillOpacity: a,
        strokeColor: PrintTools.namedColorToRgbString(stroke?.getColor()?.toString()) || rgbS,
        strokeOpacity: 0.9,
        strokeWidth: stroke?.getWidth() || defaultWidth,
        type: 'polygon',
      };
      // console.log(`polygon`, polygon, rgb, a, rgbS);
      tsa.push(polygon);
    }

    // Style for user added texts
    if (t && t.getText()) {
      const alignment = {
        bottom: 'cb',
        left: 'lm',
        right: 'rm',
      };

      const align = alignment[t.getTextAlign()] || 'cm';
      const font = t.getFont().split(' ');
      const fontSize = parseInt(font[2].slice(0, 2), 10) - 3 || 12;
      const label = (t.getText() as string).replace(/'/g, '´');
      const strokeWidth: number = t.getStroke() ? t.getStroke().getWidth() : defaultWidth;

      // Handle different color formats, such as:
      // 'red'
      // [255,0,0]
      // #ff000000
      const fallbackColor = isKMLFile ? defaultKmlColor : defaultTextColor;
      const fallbackStrokeColor = isKMLFile ? defaultKmlStrokeColor : defaultStrokeColor;
      const strokeColor = t?.getStroke()?.getColor();
      const formattedStrokeColor: string = OpenlayersHelper.olColor2Hex(
        isOlColor(strokeColor)
          ? strokeColor
          : PrintTools.namedColorToRgbArray((strokeColor || fallbackStrokeColor).toString(), fallbackStrokeColor),
      );
      const textColor = t?.getFill()?.getColor();
      const formattedTextColor: string = OpenlayersHelper.olColor2Hex(
        isOlColor(textColor)
          ? textColor
          : PrintTools.namedColorToRgbArray((textColor || fallbackColor).toString(), fallbackColor),
      );

      const text = {
        fontColor: formattedTextColor,
        fontSize: `${fontSize}px`,
        fontStyle: font[1],
        fontWeight: font[0],
        haloColor: formattedStrokeColor,
        haloOpacity: '0.8',
        haloRadius: Math.max(1.5, strokeWidth / 3),
        label: label,
        labelAlign: align,
        labelXOffset: t.getOffsetX(),
        type: 'text',
      };

      if (tsa.length === 0) {
        // GARDSBRUKSNUMMER, for GARDSKART text we use a style name that is based on areal (getTextStyle)
        tsa.push(text);
      }

      if (isKMLFile) {
        tsa.push(text);
      }

      // console.log(`text`, t.getText(), text, t);
    }

    return tsa;
  }

  // not in use, keep maybe we vill use this later
  // private getTextStyle(layer, feature, resolution): Array<any> {
  //   const p = feature.getProperties();
  //   const tsa = [];
  //   let styles = layer.getStyle();
  //   let iconStyle = null;
  //   if (typeof styles === 'function') {
  //     styles = styles(feature, resolution);
  //   }
  //   if (!styles) { return tsa; }
  //   if (styles.constructor === Array) {
  //     if (styles.length > 1) { iconStyle = styles[1]; }
  //     styles = styles[0];
  //   };
  //
  //   const t = styles.getText();
  //   if (t) {
  //     const f = t.getFont().split(' ');
  //     const sc = (t.getStroke()) ? t.getStroke().getColor() : 'rgba(255,255,255,1)';
  //     const sw = (t.getStroke()) ? t.getStroke().getWidth() : 0;
  //     const align = (t.getTextAlign() === 'bottom') ? 'cb' : 'cm';
  //     const label = t.getText().replace(/'/g, '´');
  //     const text = {
  //       type: 'text',
  //       fontColor: t.getFill().getColor(),
  //       fontSize: (parseInt(f[2].slice(0, 2), 10) - 3) + 'px',
  //       fontStyle: f[1],
  //       fontWeight: f[0],
  //       haloColor: sc,
  //       haloRadius: sw,
  //       label: label,
  //       labelAlign: align,
  //       labelYOffset: '-' + (t.getOffsetY() + 1).toString()
  //     };
  //     tsa.push(text);
  //   }
  //   if (iconStyle) {
  //     const img = iconStyle.getImage();
  //     if (!img) { return tsa; }
  //     const pRadius = 16 * img.getScale();
  //     const s = img.getSrc();
  //     const publicUrlMapfishCanFindImg = ApiConfig.imageUrl;
  //     const point = {
  //       type: 'point',
  //       externalGraphic: publicUrlMapfishCanFindImg + s.slice(1),
  //       pointRadius: pRadius
  //     };
  //     tsa.push(point);
  //   }
  //
  //   return tsa; // [{textStyle}, {point/symbol style}]
  // }

  /**
   * Use it in case you know the name of the layer, but not its property id
   * @param  lid layer id/name
   * @return     farm id
   */
  private layer2farm(lid: string): string {
    const s = lid.split('_');
    if (s.length > 2) {
      // added farm
      return s[2];
    } else {
      // first searched farm
      return this.#propertyService.getSoektEiendom().getReadableSoektGid();
    }
  }

  // Check mapfish spec for style here:
  getLayersForMapfish(map: OLMap) {
    let mapFishLayers = [];
    // liste med index på layer
    let zs = [];
    const mainThis = this;
    const olLayers = map.getLayers().getArray();
    const printboxView: undefined | View = this.#getPrintboxView();

    olLayers.forEach(olLayer => {
      // get background-olLayers and vectors (imported files and drawings)
      if (!(olLayer instanceof Group)) {
        if (!this.#isValidMapfishLayer(olLayer, printboxView)) {
          return;
        }
        const mapFishLayer = mainThis.encodeLayer(map, olLayer);
        if (mapFishLayer) {
          mapFishLayers.push(mapFishLayer);
          zs.push(olLayer.getZIndex());
        }
      } else {
        // otherLayers
        const otherLayers = olLayer.getLayers();
        otherLayers.forEach(baseLayer => {
          if (baseLayer instanceof Group) {
            const encsWithZs = mainThis.encodeGroup(map, baseLayer.getProperties());
            mapFishLayers = mapFishLayers.concat(encsWithZs.encs);
            zs = zs.concat(encsWithZs.zs);
          } else {
            if (!this.#isValidMapfishLayer(baseLayer, printboxView)) {
              return;
            }
            const enc = mainThis.encodeLayer(map, baseLayer);
            if (enc) {
              mapFishLayers.push(enc);
              zs.push(baseLayer.getZIndex());
            }
          }
        });
      }
    });

    // console.log(`mapfish getting layers`, mapFishLayers?.length, 'of total', olLayers.length);
    return PrintTools.reorderAccordingToZindex(mapFishLayers, zs);
  }
}
