import { PrintConfig } from '@config/print.config';
import { MapTools } from '@tools/map.tools';
import { MiscTools } from '@tools/misc.tools';
import { Color } from 'ol/color';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import { toSize } from 'ol/size';
import VectorSource from 'ol/source/Vector';

export class PrintTools {
  /**
   * Convert an extent to four coordinates (corners of the extent-area)
   *
   * @param  {any}        extent    extent to calculate coordinates from
   *
   * @return {Array<any>}           coordinates for each corner (4 corners)
   */
  static convertExtentToCoordinates(extent: any): any[] {
    return [
      [extent[PrintConfig.UPPER_X], extent[PrintConfig.UPPER_Y]],
      [extent[PrintConfig.UPPER_X], extent[PrintConfig.LOWER_Y]],
      [extent[PrintConfig.LOWER_X], extent[PrintConfig.LOWER_Y]],
      [extent[PrintConfig.LOWER_X], extent[PrintConfig.UPPER_Y]],
    ];
  }

  /**
   * Create a printBox layer from the given coordinates
   *
   * @param  {any}             coordinates  Coordinates of the corners to the printBox
   * @param code
   * @return {VectorLayer}              printBox layer based on the given input
   */
  static createPrintLayer(coordinates: any, code: any): VectorLayer<VectorSource> {
    const geojsonObject = {
      crs: {
        properties: {
          name: code,
        },
        type: 'name',
      },
      features: [
        {
          geometry: {
            coordinates: [coordinates],
            type: 'Polygon',
          },
          type: 'Feature',
        },
      ],
      type: 'FeatureCollection',
    };

    const propertySource = new VectorSource({
      features: new GeoJSON().readFeatures(geojsonObject),
    });

    const printStyle = MapTools.getStyle(PrintConfig.PRINT_BOX_COLOR_FILL, PrintConfig.PRINT_BOX_COLOR_LINE, 1);

    return new VectorLayer({
      source: propertySource,
      style: [printStyle],
      zIndex: 10,
    });
  }

  /**
   * [getAssociatedPropertyAll, only used for Regular print]
   * @param {object} property
   * @return {string}         [f.eks 32/2/0-32/17/0-32/19/0 m.fler
   *                          eller 72/24/0-82/2/0-112/3/0]
   */
  static getAssociatedPropertyAll(property) {
    const associatedPropertyAll = this.getAssociatedPropertyNrAll(property.grunneiendomListe);
    let minimizedList = associatedPropertyAll[0];
    if (associatedPropertyAll[0].split('-').length > 3) {
      const vStr = associatedPropertyAll[0].split('-');
      minimizedList = vStr.slice(0, 3).join('-') + ' m.fl.';
    }
    return minimizedList;
  }

  /**
   * [getAssociatedPropertyNrAll]
   * @param  {[Array]} grunneiendomListe
   * @return {[Array]}
   */
  private static getAssociatedPropertyNrAll(grunneiendomListe) {
    let grEiendomListeForTekst = '';
    let grEiendomListeForAreaStatistics = '';
    const listForBoth: string[] = [];
    for (let i = 0, len = grunneiendomListe.length; i < len; i++) {
      grEiendomListeForTekst += grunneiendomListe[i].getReadablePropertyNumber() + '-';
      grEiendomListeForAreaStatistics += grunneiendomListe[i].getReadableGid() + ',';
    }
    grEiendomListeForTekst = grEiendomListeForTekst.slice(0, -1);
    grEiendomListeForAreaStatistics = grEiendomListeForAreaStatistics.slice(0, -1);
    listForBoth.push(grEiendomListeForTekst, grEiendomListeForAreaStatistics);
    return listForBoth;
  }

  /**
   * [getAssociatedPropertyStatistic]
   * @param {object} property
   * @return {string}
   */
  static getAssociatedPropertyStatistic(property) {
    const associatedPropertyAll = this.getAssociatedPropertyNrAll(property.grunneiendomListe);
    return associatedPropertyAll[1];
  }

  /**
   * Calculate the center point of a given extent
   *
   * @param  {any}        extent  extent to calculate the center point of
   *
   * @return {Array<any>}         center point (coordinates) of given extent
   */
  static getCenterOfExtent(extent: any): any[] {
    const X = extent[PrintConfig.LOWER_X] + (extent[PrintConfig.UPPER_X] - extent[PrintConfig.LOWER_X]) / 2;
    const Y = extent[PrintConfig.LOWER_Y] + (extent[PrintConfig.UPPER_Y] - extent[PrintConfig.LOWER_Y]) / 2;

    return [X, Y];
  }

  // we have different gk type codes between gui and backend
  static getGkTypeCode(guiCode: string): string {
    switch (guiCode) {
      case 'ar5kl7':
        return '7kl';
      case 'ar5kl13':
        return '13kl';
      case 'erosjonsrisiko':
        return 'erosjon';
      default:
        return guiCode;
    }
  }

  /**
   * [getlastVerificationTime]
   * @param {object} property
   * @return {string}         [f.eks  02.03.2018 16:15]
   */
  static getlastVerificationTime(property) {
    return property.lastVerificationTime;
  }

  /**
   * It finds correct position where to add a new feature with z-index
   * @param  encFeatures array of elements with associated z-index
   * @param  z           z-index of the feature to add
   * @return             position where to place the new feature
   */
  static getPosition(encFeatures: any, z: number): number {
    for (let i = encFeatures.length - 1; i >= 0; i--) {
      if (z < encFeatures[i].zIndex) {
        continue;
      } else {
        return i + 1;
      }
    }
    return 0;
  }

  /**
   * [getReadableHnr]
   * @param  {object} property
   * @return {string}          [f.eks '0111-1/1/0']
   */
  static getReadableHnr(property) {
    return property.getReadableHnr();
  }

  /**
   * [getReadableSoektGid]
   * @param  {object} property
   * @return {string}          [f.eks '0111-1/1/0']
   */
  static getReadableSoektGid(property) {
    return property.getReadableSoektGid();
  }

  /**
   * Calculate the shortest distance between two corners of a given extent
   *
   * @param  {any}    extent    extent to find shortest distance between corners
   *
   * @return {number}           shortest distance between two corners of extent
   */
  static getSizeOfExtent(extent: any): number {
    return Math.min(
      Math.abs(Math.round(extent[PrintConfig.UPPER_X] - extent[PrintConfig.LOWER_X])),
      Math.abs(Math.round(extent[PrintConfig.UPPER_Y] - extent[PrintConfig.LOWER_Y])),
    );
  }

  /**
   * [getTitle description]
   * @param {object} property
   * @return {string}         [f.eks Landbrukseiendom 0111-1/1/0]
   */
  static getTitle(property) {
    return String(property.isFarm ? 'Landbrukseiendom ' : 'Grunneiendom ') + property.getReadableHnr();
  }

  /**
   * getVisibilityList
   * @param  addedEiendomsListe object with addedProperty
   * @return   {string}         customizable properties
   */
  static getVisibilityList(addedEiendomsListe): string {
    const complex = addedEiendomsListe;
    let visibilityString = '';
    for (let i = 0, l = complex.length; i < l; i++) {
      const c = complex[i];
      const gList = c.grunneiendomListe;
      for (let gi = 0, gl = gList.length; gi < gl; gi++) {
        const g = gList[gi];
        const gid = g.readableGid;
        if (g.isVisible) {
          visibilityString += gid + ',';
        }
      }
    }
    return visibilityString.slice(0, -1);
  }

  /**
   * When z-index is defined on a feature, get it, otherwise return -1
   * @param  l          layer from which we get style
   * @param  feature    current ol feature
   * @param  resolution current map resolution (styles depend on it)
   * @return            z-index associated to the feature
   */
  static getZindex(l: any, feature: Feature, resolution: number): number {
    let styles = l.getStyle();
    if (typeof styles === 'function') {
      styles = styles(feature, resolution);
    }
    if (!styles) {
      return -1;
    }
    if (styles.constructor === Array) {
      styles = styles[0];
    }
    const z = styles.getZIndex();
    return z ? z : -1;
  }

  /**
   * [isFarm: is this a farm]
   * @param {object} property
   * @return {boolean}        [true or false]
   */
  static isFarm(property) {
    return property.isFarm;
  }

  static makeLabel(feature: Feature) {
    const geom = feature.getGeometry();
    let textLabel = '';
    if (geom instanceof Polygon) {
      textLabel = MiscTools.formatArea(geom);
    } else if (geom instanceof LineString) {
      textLabel = MiscTools.formatLength(geom);
    }
    return {
      fontColor: '#4d4d4d',
      fontSize: '9px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      haloColor: 'rgba(255,255,255, 0.6)',
      haloOpacity: '0.7',
      haloRadius: 2,
      label: textLabel,
      labelAlign: 'cb',
      type: 'text',
    };
  }

  static makeMatriceForMapfish(matrixIds, src, proj) {
    const matrices = [];
    matrixIds.forEach((matrix, idx) => {
      const sqrZ = Math.pow(2, idx);
      matrices.push({
        identifier: matrix,
        matrixSize: [sqrZ, sqrZ],
        scaleDenominator: (src.getTileGrid().getResolution(idx) * proj.getMetersPerUnit()) / 0.28e-3,
        tileSize: toSize(src.getTileGrid().getTileSize(idx)),
        topLeftCorner: src.getTileGrid().getOrigin(idx),
      });
    });
    return matrices;
  }

  static namedColorToRgbArray(s: string, fallback: Color = [0, 0, 0, 0]): Color {
    const colors: { fill: Color; name: string; value: string }[] = [
      { fill: [0, 0, 0], name: 'Svart', value: 'black' },
      { fill: [0, 0, 255], name: 'Blå', value: 'blue' },
      { fill: [128, 128, 128], name: 'Grå', value: 'gray' },
      { fill: [0, 128, 0], name: 'Grønn', value: 'green' },
      { fill: [255, 165, 0], name: 'Orange', value: 'orange' },
      { fill: [255, 0, 0], name: 'Rød', value: 'red' },
      { fill: [255, 255, 255], name: 'Hvit', value: 'white' },
      { fill: [255, 255, 0], name: 'Gul', value: 'yellow' },
    ];

    const found = colors.find(clr => clr.value === s);
    return found?.fill || fallback;
  }

  static namedColorToRgbString(s: string): string {
    const colors = [
      { fill: [0, 0, 0], name: 'Svart', value: 'black' },
      { fill: [0, 0, 255], name: 'Blå', value: 'blue' },
      { fill: [128, 128, 128], name: 'Grå', value: 'gray' },
      { fill: [0, 128, 0], name: 'Grønn', value: 'green' },
      { fill: [255, 165, 0], name: 'Orange', value: 'orange' },
      { fill: [255, 0, 0], name: 'Rød', value: 'red' },
      { fill: [255, 255, 255], name: 'Hvit', value: 'white' },
      { fill: [255, 255, 0], name: 'Gul', value: 'yellow' },
    ];
    for (let i = 0, l = colors.length; i < l; i++) {
      const c = colors[i];
      if (c.value === s) {
        const rgb = c.fill;
        return 'rgb(' + [rgb[0], rgb[1], rgb[2]].join(', ') + ')';
      }
    }
    return s;
  }

  /**
   * Reorder mapfish layers according to their z-index
   * @param  input array with mapfish layers
   * @param  zs    array of z-indices
   * @return       reordered array of layers
   */
  static reorderAccordingToZindex(input: any[], zs: number[]): any[] {
    // First create a sortable array with mapfish layer and the z-index
    const sortArray: { ml: any; z: number }[] = [];
    for (let i = 0; i < input.length; i++) {
      sortArray.push({
        ml: input[i],
        z: zs[i],
      });
    }

    // Sort it based on ascending z-index
    // Then get only the mapfish layers
    // And return the reverse of it for mapfish to render properly
    // NB! If layers have similar z-index it's whoever's guess which one gets first
    // They should all have different z-indexes
    return sortArray
      .sort((a, b) => a.z - b.z)
      .map(x => x.ml)
      .reverse();
  }

  static splitRgba(inputStr: string) {
    if (inputStr[0] === '#') {
      return [inputStr, '1'];
    } // i.e. #FF9999

    if (inputStr.includes(',') && !inputStr.includes('(') && !inputStr.includes('rgb')) {
      inputStr = inputStr.replace('[', '');
      inputStr = inputStr.replace(']', '');
      inputStr = 'rgba(' + inputStr + ')';
    }

    const str = typeof inputStr === 'string';
    const comma = str ? inputStr.split('(')[1].split(')')[0] : 'unused';
    const [r, g, b, a] = str ? comma.split(',') : [inputStr[0], inputStr[1], inputStr[2], inputStr[3]];
    const rgb = 'rgb(' + [r, g, b].join(',') + ')';
    return [rgb, a ?? 1];
  }
} // End class
