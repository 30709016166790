import { GkAction } from '@store/gk/gk-action';

export const UPDATE_LEVEL = 'UPDATE_LEVEL';
export const RESET_LEVEL = 'RESET_LEVEL';

const DEFAULT = 'property';

export function propertyLevelReducer(state: string = DEFAULT, action: GkAction) {
  switch (action.type) {
    case RESET_LEVEL:
      return DEFAULT;

    case UPDATE_LEVEL:
      return action.payload;

    default:
      return state;
  }
}
