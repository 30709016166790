import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { MapCanvasService } from '@services/map-canvas.service';
import { MapService } from '@services/map.service';
import { StoreStates } from '@store/store-states';
import { Observable, Subject } from 'rxjs';

export type DrawLayer = {
  checked?: boolean;
  disabled?: boolean;
  id?: string;
  name?: string;
};

@Injectable()
export class DrawLayerService {
  private _drawings = new Subject<DrawLayer[]>();
  private activeTool: Observable<string>;
  private dataStore: { drawings: DrawLayer[] } = { drawings: [] }; // store data in memory

  private mapCanvas = inject(MapCanvasService);
  private mapService = inject(MapService);
  private store = inject<Store<StoreStates>>(Store);
  readonly drawings = this._drawings.asObservable();
  hasDrawnLayerData: Observable<{ disable: boolean; name: string }>;

  constructor() {
    const mapCanvas = this.mapCanvas;

    this.activeTool = this.store.pipe(select('activeTool'));
    this.hasDrawnLayerData = this.mapService.hasDrawnLayerData$;

    this.hasDrawnLayerData.subscribe(d => {
      this.addToStore(d.name, d.disable);
    });

    mapCanvas.getCanvasEditDrawing$.subscribe(editDrawing => {
      this.dataStore.drawings.forEach(el => {
        this.renameDrawing(el.id, editDrawing?.name);
      });
    });

    this.activeTool.subscribe(mapTool => {
      if (mapTool === 'DRAW') {
        this.dataStore.drawings.forEach(el => {
          this.toggleDrawingLayer({ checked: true, disabled: true, id: el.id });
        });
      } else {
        this.dataStore.drawings.forEach(el => {
          this.toggleDrawingLayer({ checked: el.checked, disabled: false, id: el.id });
        });
      }
    });
  }

  private addEditSuffix(name) {
    return name + '_redigert';
  }

  private addToStore(drawingId: string, disabled: boolean) {
    this.dataStore.drawings.push({
      checked: true,
      disabled,
      id: drawingId,
      name: 'Ny tegning',
    });

    this._drawings.next(Object.assign({}, this.dataStore).drawings);
  }

  private renameDrawing(id, name) {
    this.dataStore.drawings.find(d => d.id === id).name = name ? this.addEditSuffix(name) : 'Ny tegning';
  }

  /**
   * Removes all entries in the Service Store
   */
  clearStore() {
    this.dataStore.drawings = [];
    this._drawings.next(Object.assign({}, this.dataStore).drawings);
  }

  /**
   * Removes features from the layer from the map and from the Service Store.
   * Keeps layer and store entry.
   * @param DrawLayer item
   */
  removeDrawingFeatures(item: DrawLayer) {
    this.mapService.removeDrawOverlays(item.id);
    this.mapService.removeFeatures(item.id);
  }

  toggleDrawingLayer(item: { checked: boolean; disabled: boolean; id: string }) {
    this.dataStore.drawings.find(d => d.id === item.id).checked = item.checked;
    this.dataStore.drawings.find(d => d.id === item.id).disabled = item.disabled;
    this.mapService.setVisible(item.id, item.checked);
    this.mapService.setDrawOverlayVisible(item.id, item.checked);
  }
}
