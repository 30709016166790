import { GkAction } from '@store/gk/gk-action';
// comment in code when we vil use cachede data for addedProperties too.

export const FLUSH_MAIN_PROPERTY = 'FLUSH_MAIN_PROPERTY';

const DEFAULT = false;

export function flushMainPropertyReducer(state: boolean = DEFAULT, action: GkAction) {
  switch (action.type) {
    case FLUSH_MAIN_PROPERTY:
      return action.payload;
    default:
      return state;
  }
}
