import { Location as AngularLocation } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { MapConfig } from '@config/map.config';
import { JsonHelper } from '@helpers/json.helper';
import { OpenlayersHelper } from '@helpers/openlayers.helper';
import { ErrorService } from '@services/error.service';
import { MapService } from '@services/map.service';
import { importedFileStyles } from '@tools/mapStyles';
import { Color } from 'ol/color';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import GPX from 'ol/format/GPX';
import KML from 'ol/format/KML';
import { Geometry } from 'ol/geom';
import VectorLayer, { Options as VectorLayerOptions } from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type ImportedFile = {
  checked?: boolean;
  id?: string;
  name?: string;
};

@Injectable()
export class ImportedFileMapService {
  #location = inject(AngularLocation);
  private _files = new Subject<ImportedFile[]>();
  private addedFiles = new BehaviorSubject<boolean>(false);
  private dataStore: { files: ImportedFile[] } = { files: [] }; // store data in memory
  private errorService = inject(ErrorService);
  private fileFormats = {
    geojson: new GeoJSON(),
    gpx: new GPX(),
    kml: new KML(MapConfig.KML_DEFAULT_OPTIONS),
  };
  private mapService = inject(MapService);

  readonly files = this._files.asObservable();

  get hasAddedFiles$(): Observable<boolean> {
    return this.addedFiles.asObservable();
  }

  constructor() {
    this.mapService.dragAndDropInteraction.on('addfeatures', (event: { features: unknown; file: { name: string } }) => {
      const fileName = event?.file?.name;
      const fileFormat = fileName.split('.').pop().toLowerCase();
      if (this.fileFormats[fileFormat] === undefined) {
        this.errorService.putError('Feil fil format. Prøv følgende formatter: gpx, GeoJSON, kml.');
        return;
      }

      const map = this.mapService.getMap();
      const fileId = this.generateFileId(fileName);
      this.addToStore(fileName, fileId);
      this.makeAndAddFeature(map, event.features, fileFormat, fileId);
    });
  }

  #addFeatureStyle(feature: Feature<Geometry>): void {
    const featureStyle = feature.get('featureStyle');
    if (!featureStyle || !Object.keys(featureStyle).length) {
      // This Feature does not have explicit GK styling via the 'featureStyle' property.

      // Apply GK specific styling for the Feature types that need it:
      if (feature.get('type') === 'Text') {
        const singleStyle = OpenlayersHelper.getSingleStyle(
          feature,
          this.mapService.getMap().getView().getResolution(),
        );
        const text = singleStyle.getText();
        const img = singleStyle.getImage();

        // Remove transparency for Text, we want opaque fill:
        const fillColor: Color = text.getFill().getColor() as Color;
        if (fillColor.length > 0) {
          text.getFill().setColor([fillColor[0], fillColor[1], fillColor[2], 1]);
        }

        // Apply Text stroke according to fill:
        OpenlayersHelper.setStrokeBasedOnFill(text);

        // Remove/hide default Google Maps PushPin
        let imgSrc = (img as Icon).getSrc();
        if (imgSrc.endsWith('img/transparent.png') || imgSrc.endsWith('maki/gk_placeholder.png')) {
          singleStyle.setImage(undefined);
          imgSrc = undefined;
        }

        feature.setStyle(singleStyle);
        feature.set('featureStyle', JSON.stringify(singleStyle, JsonHelper.stripZones), true);
      }

      // Remove transparency for the line, we want opaque lines:
      if (['LineString', 'Polygon'].includes(feature.get('type'))) {
        const singleStyle = OpenlayersHelper.getSingleStyle(
          feature,
          this.mapService.getMap().getView().getResolution(),
        );
        const importedColor: Color = singleStyle.getStroke().getColor() as Color;
        if (importedColor.length > 0) {
          singleStyle.getStroke().setColor([importedColor[0], importedColor[1], importedColor[2], 1]);
        }
      }

      // Handle relative URLs
      if (feature.get('type') === 'Point') {
        const singleStyle = OpenlayersHelper.getSingleStyle(
          feature,
          this.mapService.getMap().getView().getResolution(),
        );
        const img = singleStyle.getImage();
        let imgSrc = (img as Icon).getSrc();
        // console.log(`handling imgSrc`, imgSrc);
        if (imgSrc?.length) {
          const exceptions: string[] = ['http:', 'https:', '://'];
          const isAbsoluteUrl = exceptions.some(exception => imgSrc.startsWith(exception));

          // If the img-path is relative, prep to include base-href/project path.
          if (!isAbsoluteUrl) {
            // console.log(`non-absolute imgSrc`, imgSrc);
            imgSrc = this.#location.prepareExternalUrl(imgSrc);
            // console.log(`prefixed absolute imgSrc`, imgSrc);

            img['src'] = imgSrc;
            img['src_'] = imgSrc;
            singleStyle.setImage(img);
            feature.setStyle(singleStyle);
            // console.log(`the updated singleStyle.img:`, singleStyle.getImage());
          }
        }
      }
    }
  }

  /**
   * Receives file content, adds to Service Store and adds it to Map as feature
   * @param  {string} fileName
   * @param fileId
   */
  private addToStore(fileName: string, fileId: string) {
    this.dataStore.files.push({
      checked: true,
      id: fileId,
      name: this.generateFileName(fileName),
    });

    this._files.next(Object.assign({}, this.dataStore).files);
  }

  private generateFileId(fileName: string) {
    return `${fileName}-${Date.now()}-file`;
  }

  private generateFileName(fileName: string) {
    const newfileName = fileName.substr(0, fileName.lastIndexOf('.'));
    if (this.dataStore.files.filter(f => f.name === newfileName).length) {
      return `${newfileName} (kopi)`;
    }
    return `${newfileName}`;
  }

  private makeAndAddFeature(map: any, features: any, fileFormat: string, fileId: string) {
    const uploadedSource = new VectorSource({
      features,
    });
    const importedLayer = new VectorLayer<VectorSource>({
      altitudeMode: 'clampToGround',
      fileFormat: fileFormat,
      id: fileId,
      legend: 'no',
      source: uploadedSource,
      style: importedFileStyles(fileFormat),
      type: 'imported-file',
      zIndex: 8,
    } as VectorLayerOptions);

    // Convert from KML-format to what GK expects (OpenLayers + GeoJSON);
    //   Remove img placeholder and save styles in the 'featureStyle' property.
    importedLayer
      .getSource()
      .getFeatures()
      .forEach(feature => {
        this.#addFeatureStyle(feature);

        this.mapService.rebuildFeature(feature, fileId, true);
      });

    map.addLayer(importedLayer);
    map.getView().fit(uploadedSource.getExtent(), {
      duration: MapConfig.DURATION_DEFAULT,
      padding: MapConfig.EXTENT_PADDING,
      size: map.getSize(),
    });

    this.addedFiles.next(true);
  }

  /**
   * Removes all entries in the Service Store
   */
  clearStore() {
    this.dataStore.files = [];
    this._files.next(Object.assign({}, this.dataStore).files);
  }

  readFile(fileFormat: string, fileName: string, fileContent: ArrayBuffer | string) {
    const map = this.mapService.getMap();
    const projection = map.getView().getProjection().getCode();

    const features = this.fileFormats[fileFormat.toLowerCase()].readFeatures(fileContent, {
      featureProjection: projection,
    });
    const fileId = this.generateFileId(fileName);
    this.makeAndAddFeature(map, features, fileFormat, fileId);
    this.addToStore(fileName, fileId);
  }

  /**
   * Removes file (layer) from the map and from the Service Store
   * @param {object} item
   */
  removeFileLayer(item: { id: string }) {
    this.dataStore.files = this.dataStore.files.filter(f => f.id !== item.id);
    this._files.next(Object.assign({}, this.dataStore).files);
    this.mapService.removeFileOverlays(item.id);
    this.mapService.removeLayer(item.id);
    if (this.dataStore.files.length < 1) {
      this.addedFiles.next(false);
    }
  }

  toggleFileLayer(item: { checked: boolean; id: string }) {
    this.dataStore.files.find(f => f.id === item.id).checked = item.checked;
    this.mapService.setVisible(item.id, item.checked, item.id);
  }
}
