import { GkAction } from '@store/gk/gk-action';
// comment in code when we vil use cachede data for addedProperties too.

export const FLUSH_ADDED_PROPERTY = 'FLUSH_ADDED_PROPERTY';
// export const FLUSH_ADDED_PROPERTY = 'FLUSH_ADDED_PROPERTY';

const DEFAULT = '';

export function flushAddedPropertyReducer(state: string = DEFAULT, action: GkAction) {
  switch (action.type) {
    case FLUSH_ADDED_PROPERTY:
      return action.payload;
    default:
      return state;
  }
}
