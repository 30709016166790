export class StringHelper {
  static hasLeadingSlash(mystr: string): boolean {
    return mystr.startsWith('/');
  }

  static hasTrailingSlash(mystr: string): boolean {
    return mystr.endsWith('/');
  }

  static withLeadingSlash(mystr: string): string {
    return StringHelper.hasLeadingSlash(mystr) ? mystr : '/' + mystr;
  }

  static withoutLeadingSlash(mystr: string): string {
    return StringHelper.hasLeadingSlash(mystr) ? mystr.slice(1) : mystr;
  }

  static withoutTrailingSlash(mystr: string): string {
    if (mystr.length < 1) {
      return '';
    }
    return StringHelper.hasTrailingSlash(mystr) ? mystr.slice(0, mystr.length - 1) : mystr;
  }

  static withTrailingSlash(mystr: string): string {
    return StringHelper.hasTrailingSlash(mystr) ? mystr : mystr + '/';
  }
}
