import { Action } from '@ngrx/store';

export const STANDARD_STATE = 'STANDARD';
export const PRINT_STATE = 'PRINT';
export const PROPERTY_STATE = 'PROPERTY';
export const DRAW_STATE = 'DRAW';
export const UPLOAD_STATE = 'UPLOAD';

const DEFAULT = 'STANDARD';

export function activeToolReducer(state: string = DEFAULT, action: Action) {
  switch (action.type) {
    case DRAW_STATE:
      return DRAW_STATE;
    case PRINT_STATE:
      return PRINT_STATE;
    case PROPERTY_STATE:
      return PROPERTY_STATE;
    case STANDARD_STATE:
      return STANDARD_STATE;
    case UPLOAD_STATE:
      return UPLOAD_STATE;
    default:
      return state;
  }
}
